import * as FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

export default {
    data() {
        return {
            fpPromise: null,
            fingerprint_key: window.APP_CONFIG.FINGERPRINT_KEY,
        }
    },
    methods: {
        loadFingerprint() {
            // Get the visitorId when you need it.
            if(this.fpPromise) {
                this.fpPromise
                    .then(fp => fp.get())
                    .then(result => {
                        this.$store.dispatch('pub/saveFingerprint', result)
                    })
            }
        },
    },
    mounted() {
        // Initialize the agent on page load.
        this.fpPromise = FingerprintJS.load({ apiKey: this.fingerprint_key, region: 'eu' })
    }
}
