export default {
	"Already a member?": "Redan medlem?",
	"Login": "Logga in",
	"Log out": "Logga ut",
	"Your current status": "Din nuvarande status",
	"BASIC": "ENKEL",
	"Basic": "Enkel",
	"Bronze": "Enkel",
	"Elite": "Elite",
	"VIP": "VIP",
	"Join Us": "Bli Medlem",
	"Find people near you": "Hitta människor nära dig",
	"Join now": "Gå med nu",
	"I am a": "Jag är",
	"Email": "E-post",
	"It will not be published": "Det kommer inte att publiceras",
	"E-mail address": "E-post adress",
	"SEARCH NOW": "SÖK NU",
	"I confirm that I am over 18 years old": "Jag bekräftar att jag är över 18 år",
	"Do not just think about it! Discover the hottest dating site in Australia. Every day thousands of people like you join us.": "Tänk inte så långt! Upptäck den hetaste datingsajten i Sverige. Varje dag blir tusentals människor medlem hos oss.",
	"Information": "Information",
	"All rights reserved.": "Alla rättigheter förbehållna.",
	"SUBSCRIPTION": "PRENUMERATION",
	"Other": "Övrigt",
	"All fields are required": "Alla fält är obligatoriska",
	"The fields marked in Red are required. Please fill them to continue.": "Fälten markerade med rött är obligatoriska att fylla i. Vänligen fyll i dem för att fortsätta.",
	"If you did not find a suitable answer please contact us": "Om du inte hittade ett lämpligt svar, vänligen kontakta oss",
	"Will help us to identify you": "Ska hjälpa oss att identifiera dig",
	"Invalid Username": "Ogiltigt användarnamn",
	"Subject": "Ämne",
	"Tell us how can we help you": "Berätta till oss hur vi kan hjälpa dig",
	"Please enter subject": "Ange ämne",
	"Please enter at least 10 characters": "Ange minst 10 tecken",
	"Connected to your account": "Ansluten till ditt konto",
	"Repeat Email": "Upprepa e-postadress",
	"Emails did not match": "E-postadressen matchar inte",
	"Message": "Meddelande",
	"No Recent Activity": "Ingen senaste aktivitet",

	//route labels
	"Home": "Hem",
	"Contact": "Kundservice",
	"Support": "Support",
	"Blog": "Blogg",
	"Affiliates": "Affiliates",
	"Privacy": "Sekretesspolicy",
	"Terms": "Användarvillkor",

	//routes uri
	"/login": "/logga-in",
	"/contact": "/kundservice",
	"/support": "/support",
	"/affiliates": "/affiliates",
	"/privacy": "/sekretesspolicy",
	"/terms-and-conditions": "/anvandarvillkor",
	"/cookie-policy": "/cookie-policy",
	"/subscription/exit": "/prenumeration/logga-ut",
	"/purchase-confirmation": "/kop-bekraftelse",
	"/page/:page_name": "/sida/:page_name",

	"/dashboard": "/instrumentbrada",
	"/mobile-dashboard": "/mobil-instrumentbrada",
	"/activate-profile/:token": "/aktivera-profil/:token",
	"/edit-profile": "/redigera-profil",
	"/profile/:name": "/profil/:name",
	"/my-picture": "/min-bild",
	"/messages/inbox": "/meddelande/inkorg",
	"/messages/trash": "/meddelande/skrap",
	"/messages/send/:name": "/meddelande/skicka/:name",
	"/visitor": "/gast'",
	"/favorite/my": "/favorit/min",
	"/favorite/others": "/favorit/andra",
	"/search": "/sok",
	"/search-result": "/sok-resultat",
	"/settings": "/installningar",
	"/validate-new-email-address/:token": "/validera-ny-e-postadress/:token",
	"/subscription": "/prenumeration",
	"/credits": "/kredit",
	"/elite": "/elite",
	"/faq": "/faq",
	"/delete-account": "/radera-konto",

	"/exclusive-promotion": "/exklusiv-framjande",
	"/account-deleted": "/konto-raderat",
	"/logged-out": "/logga-ut",
    "/complete-register/:token" : '/slutfor-registrering/:token',

	//login
	"Login": "Logga in",
	"Enter username or Email": "Ange användarnamn eller e-post",
	"Enter Password": "Ange lösenord",
	"Username": "Användarnamn",
	"Nickname": "Smeknamn",
	"Password": "Lösenord",
	"I forgot my password": "Jag har glömt mitt lösenord",
	"Password Reminder": "Lösenordspåminnelse",
	"Enter you email": "Ange din e-postadress",
	"Your email address": "Din e-postadress",
	"Don't have an account?": "Har du inte ett konto?",
	"REGISTER": "Bli medlem",
	"Password sent to your email": "Lösenord har skickats till din e-post",
	"This account has not been validated yet": "Det här kontot har inte validerats än",
	"LOGIN USING YOUR CREDENTIALS": "LOGGA IN MED DINA UPPGIFTER",
	"FORGOT YOUR PASSWORD?": "GLÖMT DITT LÖSENORD?",
	"Login failed": "GLÖMT DITT LÖSENORD?",
	"Username or password is incorrect": "Användarnamn eller lösenord är felaktigt",

	"We're sorry!": "Vi beklagar",
	//registration
	"You're almost done!": "Du är nästan klar!",
	"Please fill your additional account information": "Vänligen fyll i din ytterligare kontoinformation",
	"Just one step left!": "Bara ett steg kvar!",
	"Let us learn more about you. Fill in your personal data": "Låt oss veta mer om dig. Fyll i dina personuppgifter",
	"Activate your account now": "Aktivera ditt konto nu",
	"Activate your account now.": "Aktivera ditt konto nu.",
	"Please confirm your e-mail address": "Vänligen bekräfta din e-postadress",
	"Please fill in the following form to join {site_name} for free": "Fyll i följande formulär för att gå med i {site_name} gratis",
	"CONTINUE": "FORTSÄTT",

	"By clicking on the continue button I agree with the": "Genom att klicka på knappen \"Fortsätt\" accepterar jag",
	"terms": "villkor",
	"and cookies": "och kakor",

	"This site is protected by reCaptcha and the Google": "Denna webbplats är skyddad av reCaptcha och Google",
	"Privacy Policy": "Integritetspolicy",
	"and": "och",
	"Terms of Service": "Användarvillkor",
	"apply.": "tillämpa.",

	"Personal description": "Personlig beskrivning",
	"optional": "valfri",
	"FINALIZE": "AVSLUTA",
	"We have just sent you a confirmation e-mail at": "Vi har precis skickat ett bekräftelsemail till",
	"To start using our website, please click on the activation link in this email.": "För att börja använda vår webbplats, vänligen klicka på aktiveringslänken i det här e-postmeddelandet.",
	"Click here to verify your email": "Klicka här för att verifiera din e-post",
	"CLICK TO ACTIVATE": "AKTIVERA HÄR",
	"CLICK TO ACTIVATE - desktop": "AKTIVERA HÄR",
	"VERIFY MY EMAIL": "VERIFIERA MIN E-POST",
	"Not received any email yet?": "Har du inte fått någon e-post än?",
	"Please check your": "Vänligen kontrollera din",
	"spam": "skräpposten",
	"box and wait at least 10 minutes or re-send activation": "kryssa och vänta minst 10 minuter eller skicka aktiveringen igen.",
	"Enter Nickname": "Ange smeknamn",
	"Enter Your Name": "Ange ditt namn",
	"Password Required": "Lösenord krävs",
	"Enter valid Email": "Ange en giltig e-postadress",
	"Please enter at least 5 characters": "Ange minst fem tecken",
	"Enter strong password": "Ange starkt lösenord",
	"Email not available": "E-post är inte tillgänglig",
	"Nickname not available": "Användarnamn upptaget",
	"Maximum 4 number allowed": "Maximalt 4 siffror tillåtna",
	"Name should not contain any space, number or special character": "Namnet bör inte innehålla något mellanslag, siffra eller specialtecken",
	"Nickname should not contain any space or special characters": "Smeknamn får inte innehålla något mellanslag eller specialtecken",
	"Your activation mail has been sent": "Ditt aktiveringsmeddelande har skickats",
	"Complete your profile in a minute and start contacting people": "Slutför din profil på en minut och börja kontakta människor",
	"Complete your profile": "Komplettera din profil",
	"Create Profile": "Skapa Profil",
	"Your description": "Din beskrivning",
	"Confirm your Email": "Bekräfta din e-postadress",
	"We have sent you an email.": "Vi har skickat dig en e-post.",
	"Check your email to complete your registration": "Kontrollera din e-post för att slutföra din registrering",
	"and be able to speak with other users.": "och kunna prata med andra användare.",
	"If you can not find it look it up in the spam or": "Om du inte hittar det, kolla i skräpposten eller",
	"If you have not received it select one of the following options": "Om du inte har fått det väljer du något av följande alternativen",
	"junk folder": "skräpmapp",
	"Change Email": "Byt e-post",
	"Resend Email": "Skicka e-post återigen",
	"Resend": "Skicka om",
	"New Email": "Ny e-post",
	"Your activation key is invalid": "Din aktiveringsnyckel är ogiltig",
	"Please include an '@' in the email address. '{email}' doesn't have an '@'": "Ange ett '@' i e-postadressen. '{email}' har inte ett '@'",

	//public pages
	"privacy": "sekretess",
	"terms-and-conditions": "villkor",
	"about-us": "om-oss",

	//forms
	"Name": "Namn",
	"Marital Status": "Äktenskaplig status",
	"Date of Birth": "Födelsedatum",
	"Country": "Land",
	"Location": "Ort",
	"Province": "Län",
	"City": "Stad",
	"Save Changes": "Spara ändringar",
	"Select from list": "Välj från listan",
	"Day": "Dag",
	"Month": "Månad",
	"month": "månad",
	"Year": "År",
	"Prefer not to say": "Föredrar att inte säga",
	"Only profiles with photo": "Endast profiler med foto",
	"This field is required": "Detta fält krävs",
	"Please update your profile": "Uppdatera din profil",
	"Profile successfully updated": "Profilen har uppdaterats",
	"The fields marked in red are required. Please fill them to continue.": "Fälten markerade med rött är obligatoriska att fylla i. Vänligen fyll i dem för att fortsätta.",
	"Characters left": "Tecken kvar",
	"Image attached": "Bild bifogad",
	"The message field is required.": "Meddelandefältet krävs.",
	"WITH PICTURE": "MED BILD",
	"all age groups": "alla åldersgrupper",
	"EDIT DESCRIPTION": "REDIGERA BESKRIVNING",
	"DESCRIPTION": "BESKRIVNING",
	"FEATURING": "Mer info",
	"Please Select": "Vänligen välj",
	"Emails do not match": "E-postadressen matchar inte",
	"Your request was succesfully sent!": "Din förfrågan skickades!",
	"Or send us a message directly": "Eller skicka ett meddelande direkt",

	//user pages
	"My Profile": "Min profil",
	"Add Photos": "Lägg till foton",
	"Messages": "Meddelanden",
	"Favorites": "Favoriter",
	"Search": "Sök",
	"Settings": "Inställningar",
	"Visits": "Besök",
	"Likes": "Likes",
	"Membership": "Medlemskap",
	"Vip Members": "Vip Medlemskap",
	"Profile_mobile_menu": "Profil",

	"Account activation successful": "Kontoaktiveringen lyckades",
	"My chats": "Mina chattar",

	//user home
	"Quick Search": "Snabbsökning",
	"More Options": "Fler alternativ",
	"I am a": "Jag är",
	"Looking for": "Letar efter",
	"Age": "Ålder",
	"Search for": "Sök",
	"All age groups": "Alla åldersgrupper",
	"Popular Members": "Populära medlemmar",
	"New Members": "Nya medlemmar",
	"Find a Match": "Hitta en match",
	"Recent Activity": "Senaste aktivitet",
	"Add Photo": "Lägg till foto",
	"Back": "Tillbaka",
	"Become Elite": "Bli Elit",
	"Upgrade": "Uppgradering",

	"likes": "Likes",
	"EDIT MY PROFILE": "REDIGERA MIN PROFIL",
	"CONFIGURATION": "KONFIGURATION",
	"ONLINE SUPPORT": "ONLINE SUPPORT",

	//user message
	"Trash": "Borttagna objekt",
	"Delete Selected": "Radera valda",
	"No Messages Available": "Inga meddelanden tillgängliga",
	"No Messages selected": "Inga meddelanden valda",
	"Back to inbox": "Tillbaka till inkorgen",
	"Back to messages": "Tillbaka till meddelanden",
	"Select all": "Välj alla",
	"Deselect all": "Avmarkera alla",
	"Next Page": "Nästa sida",
	"Prev Page": "Föregående sida",
	"Type your message here": "Skriv ditt meddelande här",
	"Send message": "Skicka meddelande",
	"Send Icebreaker": "Skicka Isbrytare",
	"VIEW PROFILE": "VISA PROFIL",
	"DELETE": "RADERA",
	"Chat with": "Chatta med",
	"Chat": "Chatta",

	//user favorites
	"My Favorites": "Mina favoriter",
	"No likes": "Inga likes",
	"Favorite added successfully": "Favorit tillagd",
	"Favorite deleted successfully": "Favorit raderad",
	"Visit Profile": "Besök profil",

	//user visits
	"My Profile Visitors": "Min profils besökare",
	"No Visitors": "Inga besökare",

	//user photos
	"My pictures": "Mina bilder",
	"See my public profile": "Se min allmänna profil",
	"Profile photo": "Profilbild",
	"Profile Picture": "Profilbild",
	"2MB or lower.": "2MB eller mindre.",
	"2MB or lower": "2MB eller mindre",
	"Nudes not allowed in this category.": "Nakna bilder är inte tillåtna i denna kategori.",
	"PHOTO ALBUM": "FOTOALBUM",
	"Allowed images 2 megabytes or less": "Tillåtna bilder 2MB eller mindre",
	"Add picture": "Lägg till bild",
	"Main reasons for rejection": "Huvudsakliga skäl till avslag",
	"Are you sure?": "Är du säker?",
	"Are you sure you want to delete this image?": "Är du säker på att du vill ta bort den här bilden?",
	"Close": "Stäng",
	"Confirm": "Bekräfta",
	"Attaching image": "Bifoga bild",
	"The message must be at least 2 characters.": "Meddelandet måste vara minst 2 tecken.",
	"Deleting...": "Tar bort...",
	"Pictures of underage people": "Bilder på minder- åriga",
	"Personal data is visible": "Person uppgifter är synliga",
	"Fake or stolen pictures": "Falska eller stulna bilder",
	"Different gender to profile": "Ställ in rätt kön",
	"Group pictures": "Grupp bilder",
	"Weapons, drugs, violence": "Vapen, droger, våld",
	"No people visible": "Inga människor synliga",
	"Info": "Info",
	"We are reviewing your images. When they pass the verification process they will be shown to the other users.": "Vi granskar dina bilder. Efter en lyckad verifieringsprocess kommer de visas för andra användare.",

	//user profiles
	"Profile": "Profil",
	"yrs": "år",
	"years": "år",
	"yr from": "år från",
	"Physique": "Fysik",
	"Photos": "Bilder",
	"No Photos": "Inga bilder",
	"About Me": "Om mig",
	"Characteristics": "Egenskaper",
	"Prefers": "Preferenser",
	"Relationship": "Relation",
	"Shape": "Form",

	//user edit profile
	"DESCRIBE YOURSELF": "BESKRIV DIG SJÄLV",
	"GENERAL": "ALLMÄNT",
	"(Will be verified)": "(Kommer att verifieras)",
	"Sex": "Kön",
	"Seeking": "Sökande",
	"Finish your profile": "Slutför din profil",
	"Welcome! As your profile is new, we will give maximum visibility once you complete it.": "Välkommen! Eftersom din profil är ny, kommer vi att ge dig maximal synlighet när du är klar.",

	//search profile
	"Advanced Search": "Avancerad sökning",
	"OPEN TO": "ÖPPEN FÖR",
	"PREFERENCES": "PREFERENSER",
	"CHARACTER": "KARAKTÄR",
	"Search Result": "Sökresultat",
	"No Member found": "Ingen medlem hittades",
	"Change Search": "Ändra sökning",
	"Hair Color": "Hårfärg",
	"Eye Color": "Ögonfärg",
	"Body Type": "Kroppstyp",
	"Height": "Höjd",
	"Weight": "Vikt",
	"Smoker": "Rökare",
	"Ethnicity": "Etnicitet",
	"From": "Från",
	"To": "Till",

	//settings
	"Change E-mail address": "Ändra e-postadress",
	"Your email address has been successfully updated": "Din e-postadress har uppdaterats",
	"Password": "Lösenord",
	"Account Status": "Konto Status",
	"Cancel": "Annullera",
	"Delete Account": "Radera konto",
	"Notifications": "Notifikationer",
	"Receive all notifications individually": "Få alla notifikationer individuellt",
	"Receive one daily email with all my notifications": "Få en daglig e-post med alla mina notifikationer",
	"Receive a weekly email with all my notifications": "Få en e-post varje vecka med alla mina notifikationer",
	"I don\'t want to receive any notifications": "Jag vill inte få några notifikationer ",
	"(we will send you a verification email)": "(vi skickar en e-post)",
	"Current Password": "Nuvarande lösenord",
	"New Password": "Nytt lösenord",
	"Enter valid email": "Ange en giltig e-postadress",
	"Repeat New Password": "Upprepa nya lösenordet",
	"Save Settings": "Spara inställningar",
	"Passwords should match": "Lösenord ska matcha",
	"Password length should be minimum 5 characters": "Lösenordslängd bör vara minst 5 tecken",
	"Attention: Your new email address must be verified by clicking on the link that we just sent you.": "OBS: Din nya e-postadress måste verifieras genom att klicka på länken som vi just skickade till dig.",
	"I want to receive notifications by email": "Jag vill ta emot aviseringar via e-post",
	"Status": "Status",
	"Renewal": "Förnyelse",
	"Auto renewal Canceled": "Automatisk förnyelse avbruten",
	"Delete profile": "Radera profil",
	"Your account has been deleted. You will be redirected.": "Ditt konto har tagits bort. Du kommer att omdirigeras.",
	"Successfully updated": "Uppdatering lyckades",
	"Current account status": "Status för nuvarande konto",
	"Are you sure you want to cancel your subscription?": "Är du säker på att du vill avbryta ditt abonnemang?",
	"Your subscription has been successfully canceled and will not be auto-renewed": "Ditt abonnemang har avbrutits och kommer inte att förnyas automatiskt",
	"Due to technical reasons, cancellation of the renewal of the trial must be made after 8 hours of the acquisition. For any other subscription, the cancellation must be done at least 8 hours after acquisition and before 48 hours of its expiration.": "På grund av tekniska skäl, avbokning av förnyelsen av rättegången måste göras efter 8 timmar efter förvärvet. För alla andra prenumerationer, avbokningen måste göras minst 8 timmar efter förvärvet och innan 48 timmar efter dess utgång.",
	"Profile deleted. Redirecting...": "Profilen raderad. Omdirigerar...",
	"*Active subscriptions deleted this way will not be reimbursed": "*Aktiva prenumerationer som raderas på detta sätt kommer inte att ersättas.",
	"Are you sure you want to delete * your account? Your information will be permanently erased.": "Är du säker på att du vill ta bort ditt konto? Din information kommer att raderas permanent.",
	"Settings successfully updated": "Inställningarna har uppdaterats",
	"Disable Notification": "Inaktivera notifikationer",
	"The notifications have been successfully disabled.": "Notifikationer har avaktiverats.",
	"Canceled Auto-Renewal": "Avbruten automatisk förnyelse",

	//subscription
	"Only Vip members can contact more than 5 different users per day.": "Endast Vip-medlemmar kan kontakta mer än 5 olika användare per dag.",
	"Upgrade your status": "Uppgradera din status",
	"Enjoy our exclusive benefits and make new contacts": "Njut av våra exklusiva fördelar och skapa nya kontakter",
	"Users Online": "Användare online",
	"Discreet Billing. {site_name} will not appear in the invoice": "Diskret fakturering. {site_name} visas inte på fakturan",
	"Month ::: Months": "Månad ::: Månader",
	"3 days trial": "3 dagars prövning",
	"Try 3 days": "Prova 3 dagar",
	"MEMBERSHIP": "MEDLEMSKAP",
	"Select": "Välj",
	"Send messages for 3 consecutive days": "Skicka meddelanden i tre dagar i rad",
	"Only one trial subscription per user": "Endast en testprenumeration per användare",
	"In order to offer better service without interruptions, all plans will be auto renewed at the cost of one of the following subscriptions based on the pack chosen above.": "För att kunna erbjuda bättre service utan avbrott, alla planer kommer att förnyas automatiskt till ett av följande abonnemang baserade på paketet som valts ovan.",
	"for": "för",
	"For full access to all details of events, including how to cancel it,": "För full tillgång till alla detaljer om evenemang, inklusive hur du avbryter det,",
	"click here": "Klicka här",
	"for terms and conditions,": "för villkor,",
	"All profiles are reviewed and moderated by our team.": "Alla profiler granskas och modereras av vårt team.",
	"CURRENT PLAN": "AKTUELL PLAN",
	"Basic (no trial bonus) You do not have any active pack at this moment and you are a BASIC user. To increase your chances of connecting with and contacting other users, you will need to upgrade to Elite or VIP status.": "Grundläggande (ingen provbonus) Du har inget aktivt paket just nu och du är en BASIC-användare. För att öka dina chanser att bli kontaktad och kontakta andra användare, måste du uppgradera till Elite- eller VIP-status.",
	"MY BENEFITS": "MINA FÖRDELAR",
	"{discount} cheaper": "{discount} billigare",
	"save": "spara",
	"Create my profile": "Skapa min profil",
	"Read messages": "Läs meddelanden",
	"Add to Favorites": "Lägg till i favoriter",
	"Make yourself visible to other users by adding them to favorites": "Gör dig själv synlig för andra användare genom att lägga till dem i favoriter",
	"Photo Album": "Fotoalbum",
	"No fake pictures allowed": "Inga falska bilder tillåtna",
	"Album pictures": "Albumbilder",
	"Show yourself and increase your possibilities": "Visa dig själv och öka dina möjligheter",
	"Detailed profile search": "Detaljerad profilsökning",
	"Find exactly what you are looking for": "Hitta exakt vad du letar efter",
	"See other user's pics": "Se andra användares bilder",
	"Choose your favorite": "Välj din favorit",
	"Reply to and send messages": "Svara på och skicka meddelanden",
	"Make direct contact with up to five users per day": "Ta direktkontakt med upp till fem användare per dag",
	"Be shown first in searches": "Visas först i sökningar",
	"Your profile will be featured before the rest of the users": "Din profil kommer att visas före resten av användarna",
	"Make direct contact with unlimited users": "Ta direktkontakt med obegränsade användare",
	"Choose payment method": "Välj betalsätt",
	"LESS THAN": "MINDRE ÄN",
	"PER DAY": "PER DAG",
	"DISCRETION": "DISKRETION",
	"Our website name will not be disclosed": "Vårt webbplats namn kommer inte att avslöjas",
	"Our name will not be disclosed": "Vårt namn visas inte på fakturan",
	"100% Anonymous.": "100% Anonymt.",
	"Choose your subscription": "Välj ditt abonnemang",
	"Recommended": "Rekommenderad",
	"Most Popular": "Mest populär",
	"Secure payments with": "Säkra betalningar med",
	"Your message has NOT been sent. You need to upgrade your status in order to send messages.": "Ditt meddelande har INTE skickats. Du måste uppgradera din status för att skicka meddelanden.",
	"Your purchase has been successful": "Ditt köp har lyckats",
	"All subscriptions automatically renew to ensure optimum service. If you want to change the settings you can do it": "Alla prenumerationer förnyas automatiskt för att garantera optimal service. Om du vill ändra inställningarna kan du göra det",
	"here.": "här.",
	"after_here": "",

	//ad
	"Become a": "Bli en",
	"PREMIUM MEMBER": "PREMIUM MEDLEM",
	"And contact": "Och kontakta",
	"more people": "fler människor",

	"upgrade": "uppgradera",
	"Boost": "Maxa",
	"boost": "Maxa",
	"your profile": "din profil",
	"Start chatting and meet more people": "Börja chatta och träffa fler människor",
	"And get": "Och få",
	"more views": "fler profilbesök",

	"Now +3 extra Credits with each purchase": "Nu +3 extra krediter vid varje köp",
	"Best Seller": "Bästsäljare",
	"Select your Package": "Välj ditt paket",
	"Less than {amount} per credit": "Mindre än {amount} per kredit",
	"Less than": "Mindre än",
	"per credit": "per kredit",
	"Free credits": "Gratis krediter",
	"per purchase": "per köp",
	"Premium Status doesn’t allow free messages.": "Premium Status tillåter inte gratis meddelanden.",
	"Chat now": "Chatta nu",
	"Get Credits": "Köp krediter",
	"Credit": "Kredit",
	"Credits": "Krediter",
	"My credits": "Mina krediter",
	"{count} Credits left": "{count} Krediter kvar",
	"Your message has not been sent, you need credits to be able to send messages.": "Ditt meddelande har inte skickats, du behöver krediter för att kunna skicka meddelanden.",
	"Icebreaker": "Isbrytare",
	"Icebreaker today": "Isbrytare idag",
	"The cost for sending an Icebreaker is 2 credits. The Icebreaker can only be used to start a conversation or in response to another icebreaker.": "Kostnaden för att skicka en Isbrytare är 2 krediter. Isbrytare kan bara användas för att starta en konversation eller som svar på en annan isbrytare.",
	"With the acquisition of each package, users make a purchase of a pack of credits that can be used to send messages. The ratio of credits per message is ten to one, that means, the user needs to have at least ten credits in his account to send a message. The cost for sending an Icebreaker is 2 credits.": "Vid köp av varje kreditpaket erhåller användaren krediter som kan användas för att skicka meddelanden. Förhållandet mellan krediter och meddelanden är tio till ett, dvs. användaren måste ha minst tio krediter på sitt konto för att skicka ett meddelande. Kostnaden för att skicka en Isbrytare är 2 krediter.",

	"Trial pack": "Testpaket",
	"Only one trial pack per user allowed": "Endast ett testpaket per användare tillåten",
	"Used": "Använd",

	"Exclusive Promotion": "Exklusiv kampanj",
	"Account Deleted": "Kontot raderat",
	"Log out Successful": "Du är utloggad",

	"Do you like FREE LIVE SEX?": "Gillar du GRATIS LIVE SEX?",
	"Enter this code to enjoy a 10% discount:": "Njut av en 10% rabatt när du använder den här koden:",
	"Discount redeemable for your first online purchase. Expires in 2 hours.": "Den här koden är för ditt första köp online, och gäller endast de kommande 6 timmarna.",

	"This account has been deleted.": "Detta konto har tagits bort.",
	"Our algorithm has found a better option in line with your taste.": "Vår algoritm har hittat ett bättre alternativ i linje med din smak.",
	"You will be redirected in a few seconds.": "Du kommer att vidarebefordras inom några sekunder.",

	"Has added you to favorites": "Har lagt dig till favoriter",
	"Is looking at your profile": "Ser på din profil",
	"See profile": "Se profil",
	"See message": "Se meddelande",

	"I think I am in love": "Jag tror jag är kär",
	"Hey! I'm winking at you": "Hej! Jag blinkar åt dig",
	"Tell me more about you. Fancy a chat?": "Berätta mer om dig. Vill du chatta?",
	"Some flowers for you!": "Några blommor till dig!",
	"Sending you a kiss": "Skickar dig en kyss",
	"Surprise, it\'s me!": "Surprise, det är jag!",
	"Hi, how are you?": "Hej, hur går det?",
	"Let's get naughty": "Ska vi ha lite kul…",

	"Get 5 times the regular response rate. Premium members benefits:": "Få fem gånger mer den vanliga svarsfrekvensen. Förmåner med ett premium medlemskap:",
	"Appear high up on searches": "Visas högt upp på sökningar",
	"Distinctive badge that increases your visibility": "En symbol som ökar din synlighet",
	"5 Free Icebreakers a day": "5 gratis isbrytare om dagen",
	"In addition to this, a voucher for a 15% Webcam discount": "Utöver detta, en kupong för 15% rabatt på webbkamera",

	"The Icebreaker can only be used to start a conversation or in response to another icebreaker.": "Isbrytare kan endast användas för att starta en konversation eller som svar på en annan isbrytare.",

	"Succesful purchase": "Köpet lyckades",
	"Your purchase has been successful.": "Ditt köp har lyckats.",
	"It can take a few minutes to update.": "Det kan ta några minuter att uppdatera.",
	"You will be redirected.": "Du kommer att omdirigeras.",

	"Changes can take some minutes to update.": "Ändringar kan ta några minuter att uppdatera.",

	"Yearly Package": "Årligt paket",
	"Package": "Paket",
	"Buy Now": "Köp Nu",
	"The Elite status allows you to contact up to 5 different users every day by sending them unlimited messages.": "Med Elite-status kan du kontakta upp till 5 olika användare varje dag genom att skicka dem obegränsade meddelanden.",

	"Exclusive offer": "Exklusivt erbjudande",
	"Welcome": "Välkommen",
	"Mary from {domain_name} support": "Maria från {domain_name} support",
	"Welcome to {domain_name}. We hope you find what you are looking for.": "Välkommen till {domain_name}. Vi hoppas att du hittar det du letar efter.",
	"Here are some Pro Tips to be more successful and meet people:": "Här är några Pro-tips för att lyckas bättre och träffa människor:",
	"Upload a profile picture to get more connections.": "Ladda upp en profilbild för att få fler anslutningar.",
	"Send an icebreaker to get a conversation easily started.": "Skicka en isbrytare för att enkelt kunna komma igång.",
	"If you like someone don’t forget to add them to favorites so they will know.": "Om du gillar någon glöm inte att lägga till dem i favoriter så att de vet det.",
	"And of course, if you like somebody send a message and ask for a date!": "Och naturligtvis, om du gillar någon skicka ett meddelande och be om en dejt!",
	"If you need any extra help, please contact our ": "Om du behöver extra hjälp, vänligen kontakta vår ",
	"support": "support",
	". We will be happy to help you.": ". Vi hjälper dig gärna.",
	"Try your luck": "Prova din lycka",
	"64% of our users have a date in the first 2 weeks.": "64% av våra användare har en dejt inom de första 2 veckorna.",
	"Good Luck!.": "Lycka till!.",
	"*This message will be deleted upon closing.": "*Det här meddelandet raderas vid stängning.",

	"Private picture": "Privat bild",
	"Private pictures will appear blurred out. Only users that have already paid will be able to see them.": "Privata bilder kommer att vara suddiga. Endast användare som redan har betalat kan se dem.",
	"Name change": "Namnbyte",
	"If you want to change your name or nickname, please contact our support team and we will do it within 24h.": "Om du vill ändra ditt namn eller smeknamn, vänligen kontakta vårt supportteam så gör vi det inom 24 timmar.",
	"See all images": "Se alla bilder",
	"Unlock all private photos forever for just 20 credits": "Lås upp alla privata bilder för alltid för bara 20 krediter",
	"Unlock forever all private pictures": "Lås upp alla privata bilder för alltid",

	"This email address is not registered, please sign up to log in.": "Den här e-postadressen är inte registrerad, vänligen registrera dig för att logga in.",
	"Congratulations, your account is now active.": "Grattis! Ditt konto är nu aktivt.",
	"Complete one last step and have fun!": "Slutför ett sista steg och ha kul!",
	"OR": "ELLER ",

	"We sent you an email with a link to activate your account valid for the next 12 hours to:": "Vi har skickat ett mejl med en aktiveringslänk till e-postadressen nedan, den kommer vara giltig i 12 timmar:",
	"If you haven\'t received it in your inbox or ": "Om du inte har fått något i din inkorg eller i",
	"or you want to modify the email address, click on the button below.": "eller om du vill ändra din e-post, klicka på knappen nedan...",
	"We sent you an email to verify your account, the email will be valid for the next 12 hours:": "Vi har skickat dig en e-post för att verifiera ditt konto, e-posten kommer att vara giltig de kommande 12 timmarna:",
	"If you can\'t find it in your inbox or spam, you can edit or resend it": "Om du inte kan hitta den i din inkord eller i skräpposten, kan du redigera eller skicka om den",
	"Resend or change email": "Skicka om eller ändra e-postadressen",
	"Please use the same email you registered.": "Vänligen använd samma e-postmeddelande som du registrerade.",
	"Connect with Google": "Anslut med Google",
	"Connect with Yahoo": "Sign up with Yahoo",
	"Connect with Microsoft": "Anslut med Hotmail",
	"Or turn back": "Go back",
	"Complete your registration": "Complete your registration",

	"It will not be shared": "Delas ej med andra användare",
	"It will never be shared. 100% Privacy": "Delas aldrig med andra användare. 100% integritet",

	"Unlock all private pictures forever.": "Lås upp alla privatbilder för alltid.",
	"Unlock": "Lås upp",

	"You will be redirected in a second": "Du håller på att omdirigeras till nästa sida",

	"Card payment": "Kortbetalning",
	"Bank transfer": "Banköverföring",
	"Prepaid card": " Förbetalt kort",

	"Error": "Fel",
	"page not found": "Sidan hittades inte",
	"Back to homepage": "Tillbaka till hemsidan",

	"per month": "per månad",
	"week": "vecka",
	"{count} week": "{count} vecka",

	"Your profile has been blocked": "Din profil har blockerats",
	"In order to offer the best possible user experience, we block all profiles that haven\'t sent a message within 21 days of registration.": "För att erbjuda bästa möjliga användarupplevelse blockerar vi alla profiler som inte har skickat ett meddelande inom 21 dagar efter registreringen.",
	"Please acquire a credit package to unlock your profile and restore all its features.": "För att låsa upp din profil och få tillgång till samtliga funktioner på sidan kan du anskaffa ett kreditpaket.",
	"Restore access to:": "Gå tillbaka till:",
	"All messages": "Alla Meddelanden",
	"Users search": "Användarsökning",
	"Your contacts": "Dina kontakter",
	"All favourites": "Alla favoriter",
	"All pictures": "Alla bilder",
	"Send Icebreakers": "Skicka Isbrytare",
	"Unblock Profile": "Lås upp profilen",

	"This website uses its own and third-party cookies and identifiers to improve accessibility and analyse our traffic. If you continue browsing, we consider that you accept its use. For more information visit our": "Denna webbplats använder sina egna samt tredjepartscookies och identifierare för att förbättra tillgängligheten och för att analysera vår trafik. Om du fortsätter surfa anser vi att du accepterar dess användning. Mer information finns i vår",
	"Cookies Policy": "cookie-policy",

	"DATE GUARANTEE": "DEJT GARANTI",
	"Take advantage of our Date guarantee": "Dra nytta av din dejt garanti för riktiga dejter, dvs",
	"No date? Money Back!": "Ingen dejt? Pengarna tillbaka!",
	"No risk": "Inga risker",
	"Guarantee of success": "Succé garanti",
	"Terms and Conditions": "Villkor",

	"Boost your visibility": "Öka din synlighet",
	"SAVE": "SPARA",
	"Add 3 months premium": "Lägg till 3 månaders premium",
	"Add {months} months premium": "Lägg till {months} månaders premium",

	"We guarantee you a real date.": "Vi garanterar dig en riktig dejt.",
	"YES, I WANT A DATE": "JA, JAG VILL HITTA EN DEJT",

	"Terms and conditions of the date guarantee. In order to fulfill all refund conditions, you must have a fully completed profile for a period of at least six months and your personal description in your profile. Your profile must contain at least one approved picture throughout this period. Furthermore, each week, you must contact at least seven different members located close to you (radius of 100km) within your age range (-5/+5 years). If you fulfill all the conditions listed above and you still were not able to find a date, we will full refund all the payments you made during this time. This guarantee is only valid for the first 12 months following your registration. Good luck!": "Villkor för dejtgarantin. För att uppfylla alla återbetalningsvillkoren måste du ha en fullständig profil tillsammans med din personliga beskrivning under en sex månaders period. Din profil måste innehålla minst en godkänd bild under denna period. Dessutom måste du kontakta minst sju olika medlemmar varje vecka, som bor nära dig (max 100 km avstånd) inom ditt åldersintervall (-5 / + 5 år). Om du uppfyller alla ovannämnda villkoren och du fortfarande inte lyckas att hitta en dejt, kommer vi att återbetala alla betalningarna som du har gjort under denna period. Denna garanti gäller endast de första 12 månaderna efter din registrering. Lycka till!",

	"You have {count} days premium left": "Ditt premium löper ut om {count} dagar",
	"Check our special offer before deleting your account!": "Kolla in vårt specialerbjudande innan du raderar ditt konto!",
	"Delete my account": "Radera mitt konto",
	"Free": "Gratis",

	"Premium, Elite and VIP packages are auto-renewable, which means they will auto-renew when they expire.": "Premium-, Elite- och VIP-paketen förnyas automatiskt, vilket innebär att de kommer att förnyas automatiskt när de har gått ut.",
	"and can confirm that I am 18 years or older.": "och jag bekräftar att jag är över 18 år.",

	"COMPLETE YOUR PURCHASE": "BEKRÄFTA DITT KÖP",
	"Payment details": "Betalningsinformation",
	"Total": "Totalt",
	"3-digit number on the back of your credit card": "En 3-siffrig kod som är placerad på kortets baksida",
	"MM / YY": "MM / ÅÅ",
	"MM": "MM",
	"YY": "ÅÅ",
	"Name on card": "Kortägarens namn",
	"Charges will appear discreetly as tpmpsup.com": "Avgiften kommer att diskret visas som virtualcharges.com",
	"Buy Now": "Köp nu",
	"Premium package 3 Months": "Premium paket 3 månader",

	"SPECIAL OFFER": "SPECIALERBJUDANDE",
	"Unlock your account with our special offer": "Lås upp ditt konto med vårt specialerbjudande",
	"Take advantage of our special offer to unlock your profile and restore all its features.": "Utnyttja vårt speacialerbjudande för att låsa upp din profil och återställa alla dess funktioner.",

	"This purchase was declined. Please check your details and try again.": "Ditt köp misslyckades. Vänligen kontrollera dina uppgifter och prova igen.",
	"Your purchase was succesful.": "Ditt köp har genomförts.",

	"card_number_require": "Kortnummer krävs",
	"card_number_invalid": "Kortnummer är ogiltigt",
	"card_name_require": "Kontoinnehavarens namn krävs",
	"card_name_invalid": "Kontoinnehavarens namn är ogiltigt",
	"card_cvc_require": "CVC krävs",
	"card_cvc_invalid": "CVC är ogiltigt",
	"card_expiry_require": "Kort giltighetstiden krävs",
	"card_expiry_invalid": "Kort giltighetstiden är ogiltig",

	"Private photo": "Privat foto",
	"Search for someone now": "Sök efter någon nu",
	"Edit": "Redigera",

	"Locked Album": "Låst album",
	"Get credits to unlock all albums": "Skaffa krediter för att visa albumen",
	"picture ::: pictures": "bild ::: bilder",
	"Open": "Öppna",

	"Receive notifications": "Aktivera aviseringar",
	"I want to be notified when I receive a message": "Jag vill bli notifierad när jag får ett nytt meddelande",
	"No": "Nej",
	"Yes": "Ja",

	"logout_confirm_title": "Logga ut",
	"Are you sure you want to log out?": "Är du säker på att du vill logga ut?",

	"Your payment has been cancelled": "Din betalning har avbrutits",
	"Something went wrong, please try again in 10 minutes": "Något gick fel, försök igen om 10 minuter",

	"Please accept the terms and conditions to continue": "Godkänn villkoren för att fortsätta",
	"By checking this box and clicking continue I accept the": "Genom att markera denna ruta och klicka på fortsätt accepterar jag",
	"rg_terms": "Användarvillkor",
	"rg_privacy": "Integritetspolicy",
	"rg_cookies": "Cookiepolicy",
	"I am aware of the use of fictitious profiles on the website and I confirm that I am of legal age.": "Jag är medveten om användningen av fiktiva profiler på webbplatsen och bekräftar att jag är myndig.",

	"Cookie": "Cookie",
	"Responsible Party": "Ansvarig part",
	"Category": "Kategori",
	"Cookie Name": "Namn",
	"Purpose": "Syfte",
	"Lifespan": "Livslängd",
	"Type of Cookie": "Typ av Cookie",
	"Strictly necessary": "Strikt nödvändigt",
	"Functional": "Funktionell",
	"Performance": "Prestanda",
	"Advertising": "Advertising",
	"cookie-policy": "cookie-policy",
	"cookie policy": "policy för cookies",

	"We Care About Your Privacy": "Vi bryr oss om din integritet",
	"This site uses its own, technical and third party cookies to improve navigation performance, analyzing visitors behaviour to help us and our marketing partners measure campaign results and personalized advertising. By clicking \"Accept All\" you are agreeing to all of these cookies.": "Denna webbplats använder sina egna, tekniska och tredjepartscookies för att förbättra navigationsprestandan, analysera besökarnas beteende för att hjälpa oss och våra marknadsföringspartner att mäta kampanjresultat och personlig reklam. Genom att klicka på \"Acceptera alla\" godkänner du alla dessa cookies.",
	"Accept All": "Acceptera alla",
	"Cookies Settings": "Cookieinställningar",
	"About your privacy": "Gällande din integritet",
	"We use cookies for traffic balancing, session identification, analytics and marketing attribution. You can opt-in or opt-out of any or all of them from this panel.": "Vi använder cookies för trafikbalansering, identifiering av sessioner, analys och marknadsföringstillskrivning. Du kan välja eller välja bort någon eller alla från den här panelen.",
	"You will be able to change your set up at any time from our :cookies_policy page": "Du kan när som helst ändra dina inställningar från vår :cookies_policy sida",
	"Accept all": "Acceptera alla",
	"Manage Consent Preferences": "Hantera preferenser för samtycke",
	"Technical (Mandatory)": "Teknisk (obligatorisk)",
	"Performance (Monitor website performance)": "Prestanda (Övervaka webbplatsens prestanda)",
	"Functional (User experience improvement)": "Funktionell (förbättring av användarupplevelse)",
	"Advertising (Marketing measurement)": "Annonsering (marknadsföringsmätning)",
    "Advertising (Personalization)": "Reklam (anpassning)",
	"Save Selection": "Spara urval",
	"Reject All": "Avisa alla",

	"We and our partners process data to provide:": "Vi och våra partners bearbetar data för att tillhandahålla:",
	"Use precise geolocation data. Actively scan device characteristics for identification. Store and/or access information on a device. Personalised ads and content, ad and content measurement, audience insights and product development.": "Använd exakta geolokaliseringsdata. Skanna enhetens egenskaper aktivt för identifiering. Lagra och / eller få åtkomst till information på en enhet. Personliga annonser och innehåll, annons- och innehållsmätning, publikinsikter och produktutveckling",
	"List of partners": "Lista över partners",
	"Cookie Policy": "Cookiepolicy",
	"These cookies are used in connection with the tracking of actions related to advertisements. For example, these cookies will remember that you have visited {name} and can be used to show you a personalized advertisement on another website. Please check the following links of the platforms where we promote ourselves, Google, Microsoft and Twitter. for more information.": "Dessa kakor används i samband med spårningsåtgärder relaterade till reklam. Dessa cookies kommer ihåg till exempel att du har besökt {name} och kan användas för att visa dig personlig annonsering på en annan webbplats. Kontrollera följande länkar för de plattformar vi annonserar själva på, Google, Microsoft och Twitter.",
	"Delete cookies": "Radera cookies",
	"Save selection": "Spara urval",
	"Reject all": "Avvisa alla",

	"{name} hasn't replied to you yet. Send another message!": "{name} har inte svarat dig ännu. Skicka ett meddelande till!",
	"Validate my Ice-breaker": "Bekräfta min isbrytare",

	"Distance": "Avstånd",
	"now": "nu",

	"Starting  June 1, 2023 onwards, we will not process payments with PaySafeCard.": "Från och med den 1a juni 2023 och framåt, kommer vi inte att behandla betalningar med PaySafeCard.",

	"Dear User,": "Kära användare,",
	"Thanks for your interest in joining our website, but, in order to keep a gender balance close to 50/50 men and women, registration is not allowed for men, at the moment.": "Tack för ditt intresse att gå med på vår webbplats, men för att hålla en könsfördelning nära 50/50 män och kvinnor är registrering inte tillåten för män för närvarande.",
	"If you still want a date, click on the button below and try one of the best websites online.": "Om du fortfarande vill ha en dejt, klicka på knappen nedan och prova en av de bästa webbplatserna online.",
	"Try Now": "Försök nu",

	"Your account has been deleted.": "Ditt konto har raderats.",
	"According to your choices, our algorithms have found a better option for you.": "Enligt dina val har våra algoritmer hittat ett bättre alternativ för dig.",
	"See More": "Se mer",

	"10 credits for you!": "10 krediter åt dig!",
	"Get 3 free Icebreakers": "Få 3 isbrytare gratis",
	"Free Icebreakers": "Isbrytare gratis",
	"Congratulations, you have received 3 Icebreakers!": "Grattis! Du har fått 3 isbrytare!",

	"Apple pay only works with the Safari browser. Please open your Safari browser and login to your account in order to proceed with this payment.": "Apple Pay fungerar enbart med Safari som browser. Vänligen öppna din Safari browser för att logga in på ditt konto och slutföra betalningen.",

	"No nudity": "Ingen nakenhet",
	"Nudity allowed": "Nakenhet tillåten",

	"Edit profile": "Redigera profil",
	"Online": "Online",
	"Step": "Nästa",
	"Completed": "Fullföljt",
	"Help": "Hjälp",
	"or Drag and drop": "eller dra och släppa",

	"You will be able to send messages in": "Du kommer att kunna skicka meddelande om",
	"Send": "Skicka",
	"Success": "Lyckat",
	"Start a conversation with {name}": "Starta en konversation med {name}",
	"Failed": "Misslyckat",
	"Congratulations, your account has been successfully created.": "Grattis, ditt konto har skapats.",
	"Contact our support": "Kontakta vår kundtjänst",

	"About": "Om",
	"Scale": "Justera",
	"Rotate": "Rotera",

	"No favorites added": "Inga favoriter tillagda",

	"Enter Email": "Fyll i E-post",

    "We have sent you an email to:": "Vi har skickat ett e-postmeddelande",
    "Please confirm by clicking the \"delete account\"  button in that email and your account will be permanently deleted from our system.": "Vänligen bekräfta genom att klicka på knappen \"Radera konto\" i e-postmeddelandet så kommer ditt konto att permanent raderas från vårt system.",
    "DELETE ACCOUNT": "Radera konto",

  	"Didn\'t find what you were looking for?": "Hittade du inte vad du letade efter?",
	"Please send us an email and we will get back to you.": "Vänligen skicka ett e-postmeddelande till oss så återkommer vi till dig.",
	"Or contact us using the form": "Eller kontakta oss via formuläret.",

	"BOOST YOUR ACCOUNT": "BOOSTA DITT KONTO",
	"We will send you an email to boost your account:": "Vi kommer att skicka ett e-postmeddelande för att boosta ditt konto:",
	"Get Free exclusive benefits": "Få exklusiva gratisförmåner",
	"Premium Status 24h": "Premiumstatus i 24 timmar",
	"5 Free Credits": "5 Gratiskrediter",
	"3 Free Icebreakers": "3 Gratis Isbrytare",
	"IMPROVE ACCOUNT": "FÖRBÄTTRA KONTO",

	"COMPLETE REGISTRATION": "SLUTFÖR REGISTRERING",
	"Confirm your email and complete registration": "Bekräfta din e-post och bekräfta registreringen",
	"Benefits": "Förmåner",
	"More Visibility": "Mer synlighet",
	"Send Messages": "Skicka meddelanden",

    "Your registration is completed": "Din registrering är klar.",

    "Disclaimer": "Ansvarsfriskrivning",
    "Physical contact may not be possible in all situations. The primary goal of {name} is entertainment, not facilitating in-person meetings or dating. {name} uses fictitious profiles, marked with a heart icon, which are intended solely for messaging and entertainment purposes.": "Fysisk kontakt kan inte alltid vara möjlig. {name} har underhållning som sitt primära syfte, inte att organisera fysiska möten eller dejting. {name} använder fiktiva profiler, markerade med ett hjärtikon, vars enda syfte är att erbjuda underhållning genom meddelanden.",
    "By using this website and its entertainment services, you acknowledge and agree that payments may be processed by a separate entity within our group of companies. This entity operates solely for payment processing purposes and may differ from the operating company providing the entertainment services.": "Genom att använda denna webbplats och dess underhållningstjänster erkänner och godkänner du att betalningar kan behandlas av en separat enhet inom vår företagsgrupp. Denna enhet arbetar enbart för betalningsbehandlingsändamål och kan skilja sig från det företag som tillhandahåller underhållningstjänsterna.",

    "Free daily icebreaker": "Gratis daglig Isbrytare",
	"We will send you an email to:": "Vi kommer att skicka ett e-postmeddelande till dig:",
	"Open it and get your free Icebreaker.": "Öppna det och få din gratis Isbrytare.",
	"Get Icebreaker": "Få Isbrytare",
	"Congratulations, you received a free Icebreaker.": "Grattis, du har fått en gratis Isbrytare.",

    "Your account has been updated": "Ditt konto har uppdaterats."

}
