import publicService from '../../services/public'
import {checkNull, safeRedirect} from '@/helpers'

export default {
	namespaced: true,
	state: {
		step_no: 1,
		form_values: null,
		route_from: null,
		route_prev: null,
		route_to: null,
		city_pages: [],
		marketing_partners: null,
		alerts: [],
		allow_leave: false,
        user_mainstream: false,
	},
	getters: {
		stepNo(state) {
			return state.step_no
		},
		formValues(state) {
			return state.form_values
		},
        webcamUrl(state, getter, rootState, rootGetter) {
            if (!rootGetter["auth/isAuthenticated"]) {
                return window.APP_CONFIG.WEBCAM_URL;
            }
            return state.form_values?.webcam_url;
        },
        userMainstream(state) {
            return state.user_mainstream;
        },
		routeFrom(state) {
			return state.route_from
		},
		routePrev(state) {
			return state.route_prev
		},
		routeTo(state) {
			return state.route_to
		},
		cityPages(state) {
			return state.city_pages
		},
		getMarketingPartners(state) {
			return state.marketing_partners
		},
		allowLeave(state) {
			return state.allow_leave
		}
	},
	mutations: {
		setStepNo(state, payload) {
			state.step_no = payload
		},
		setFormValues(state, payload) {
			state.form_values = payload
		},
        setUserMainstream(state, payload) {
            state.user_mainstream = payload
        },
		setRouteFrom(state, payload) {
			state.route_from = payload
		},
		setRoutePrev(state, payload) {
			state.route_prev = payload
		},
		setRouteTo(state, payload) {
			state.route_to = payload
		},
		setCityPages(state, payload) {
			state.city_pages = payload
		},
		setMarketingPartners(state, payload) {
			state.marketing_partners = payload
		},
		updateMarketingPartner(state, payload) {
			state.marketing_partners = state.marketing_partners.map(obj => {
				if (obj.id === payload.id) {
					return Object.assign(obj, payload);
				}
				return obj
			})
		},
		allowLeave(state, payload) {
			return state.allow_leave = payload
		}
	},
	actions: {
		getFormValues(context, payload) {
			publicService.getNewFormValues()
				.then(res => {
					context.commit('setFormValues', res.data);
					context.dispatch('checkUserMainstream');
				})
		},
        checkUserMainstream(context) {
            if (context.state.user_mainstream) {
                return
            }
            context.commit("setUserMainstream", !!context.state.form_values?.user_mainstream);
        },
		getCityPages(context) {
			return publicService.getCityPages()
				.then(res => {
					context.commit('setCityPages', res.data)
				})
		},
		getFaqItems(context, payload) {
			return publicService.getFaqItems(payload)
		},
		getContactItems(context, payload) {
			return publicService.getContactItems(payload)
		},
		sendSupportMessage(context, payload) {
			return publicService.sendSupportMessage(payload)
		},
		saveIpqsResults(context, payload) {
			return publicService.saveIpqsResults(payload)
		},
		getEmailAutoFillDomains(context, {country_id}) {
			country_id = checkNull(country_id) ? country_id : ''
			return publicService.getEmailAutoFillDomains(country_id)
		},
		getProvinces(context, {country_id}) {
			return publicService.getProvinces(country_id)
		},
		getCities(context, {province_id}) {
			return publicService.getCities(province_id)
		},
		getPage(context, s_page) {
			return publicService.getPage(s_page)
		},
		getInboxSpamUrl(context, {email}) {
			return publicService.getInboxSpamUrl({email})
		},
		getBanners(context) {
			return publicService.getBanners()
		},
		loadMarketingPartners(context, payload) {
			if (!context.getters.getMarketingPartners) {
				publicService.getMarketingPartners().then(response => {
					let partners = response.data.data.map(obj => { obj.show = false; obj.selected = true; return obj; });
					context.commit('setMarketingPartners', partners);
				}).catch(error => console.trace(error));
			}
		},
		allowLeave(context, payload) {
			console.log('dispatched allowLeave', payload)
			context.commit('allowLeave', payload)
		},
        saveAnonymousClick(context, {data, url}) {
            return publicService.saveAnonymousClick(data).then(() => {
                safeRedirect(url)
            })
        },
		saveFingerprint(context, payload) {
			return publicService.saveFingerprint(payload)
		}
	}
}
